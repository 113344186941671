import {
  FaChartLine,
  FaShieldAlt,
  FaCog,
  FaServer,
  FaRocket,
  FaUserClock,
} from "react-icons/fa";
export const services = [
  {
    id: 1,
    title: "Exchange Testing",
    description: "Comprehensive testing solutions for exchange platforms",
    icon: <FaChartLine className="text-4xl text-[#313F78]" />,
    details:
      "Advanced testing methodologies ensuring robust exchange operations",
  },
  {
    id: 2,
    title: "Security Analysis",
    description: "In-depth security assessment services",
    icon: <FaShieldAlt className="text-4xl text-[#313F78]" />,
    details: "State-of-the-art security protocols and vulnerability testing",
  },
  {
    id: 3,
    title: "Performance Optimization",
    description: "Enhanced system performance solutions",
    icon: <FaRocket className="text-4xl text-[#313F78]" />,
    details: "Streamlined optimization techniques for maximum efficiency",
  },
];
