import MainContact from "../Components/ContactUsComponent/mainContact"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
import { useEffect } from "react"

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <section>
                {/* <Navbar /> */}
            </section>
            <section>
                <MainContact/>
            </section>
            <section>
                <Footer />
            </section>
        </>
    )
}
export default ContactUs