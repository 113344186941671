import {
  FaBalanceScale,
  FaBolt,
  FaBuilding,
  FaChartLine,
  FaClipboardCheck,
  FaClock,
  FaCode,
  FaCog,
  FaCogs,
  FaHandshake,
  FaLock,
  FaNetworkWired,
  FaRocket,
  FaServer,
  FaShieldAlt,
  FaUserClock,
} from "react-icons/fa";
import bannerImage from "../../Images/bannerImageEn.png";

export const enTranslation = {
  navbar: {
    home: "Home",
    about: "About Us",
    contact: "Contact Us",
    faq: "FAQ",
    exchange: "Exchange & Clearing",
    services: "Services",
    archer: "Archer Solutions",
  },
  heroSection: {
    heading: "Experienced, Personalized IT",
    subheading: "Services",
    description:
      "Get expert IT support tailored just for you. From troubleshooting to optimization, we've got you covered.",
    buttonLabel: "Schedule a Free Consultation",
    count: {
      employee: "Employees",
      clients: { c1: "Worldwide", c2: "Clients" },
      projects: { p1: "Delivered", p2: "Projects" },
      experience: { e1: "Years of", e2: "Experience" },
    },
  },
  secondSection: {
    heading: "Empowering Digital Success: Owision AB",
    description:
      "Owision AB is a leading web and mobile app development Company based out of Stockholm, Sweden. We follow the 'Client First' motto and deploy innovation at every stage of your app’s development journey. Started out in 2018, Owision has facilitated the release of leading web & mobile solutions that took the digital world by surprise.",
    buttonLabel: "Explore More",
  },
  thirdSection: {
    one: "COACH DEVELOPMENT TEAMS ABOUT SOFTWARE QUALITY ASSURANCE",
    two: "IDENTIFY WHAT AND HOW TO’S OF TEST AUTOMATION",
    three: "IDENTIFYING THE BEST TOOLS FOR SOFTWARE PROJECT AUTOMATION",
    four: "OUTLINING THE PROCESSES FOR UAT",
    five: "TEST RESULTS REPORTING",
    bannerImage,
  },
  servicesOffered: {
    heading: "What We Deliver",
    services: {
      s1: {
        title: "Managed Services",
        description:
          "Free up your internal resources to focus on the business by letting us handle day-to-day support services, management, and monitoring of your IT.",
      },
      s2: {
        title: "SAP Implementation & Support",
        description:
          "We specialize in ERP Consulting and SAP Practice. We offer tailored, cost-effective solutions for maintaining, supporting, and enhancing existing SAP applications with expertise in industry-specific software and best practices.",
        button: "Read More",
      },
      s3: {
        title: "Gen AI",
        description:
          "Gen AI Service specializes in crafting bespoke cloud solutions, precisely tailored to your business needs and goals, maximizing performance and efficiency.",
      },
      s4: {
        title: "Web Development",
        description:
          "Our web development services can help you establish an impactful online presence and reach your target audience effectively.",
      },
      s5: {
        title: "Mobile Development",
        description:
          "We can help you create a customized mobile app that aligns with your brand and goals, with expertise in various mobile platforms.",
      },
      s6: {
        title: "Testing Service",
        description:
          "With our proficiency in testing services, we are committed to assisting you in identifying and implementing the most effective testing solutions tailored to your specific requirements and objectives.",
      },
      s7: {
        title: "IT Consulting & Advisory",
        description:
          "The right technology, implemented properly, appropriately managed and monitored, can lead to significant gains in growth.",
      },
    },
  },
  footer: {
    heading: {
      about: "About",
      link1: "Home",
    },
    quickLink: {
      heading: "Quick Links",
      link1: "FAQ",
      link2: "Privacy Policy",
    },
    contact: {
      heading: "Contact Us",
      email: "info@owision.com",
      phone: "+46-84527113",
    },
    getintouch: "Get in Touch",
    copyright: "Copyright © 2024 owision - All Rights Reserved.",
  },

  about: {
    heading: "Dedicated to delivering excellence",
    subheading: "Everyone has a story. Here is ours.",
    ourStory: {
      s1: " Welcome to Owision, where we excel in crafting cutting-edge software solutions for transformative digital experiences. Our commitment to excellence drives us to push technological boundaries.",
      s2: "Our client-centric approach sets us apart. Recognizing the uniqueness of each business, we delve into our clients' needs, challenges, and goals. This tailored strategy enables us to create customized software solutions for maximum efficiency and impact",
      s3: "We bring expertise across Fintech, Telecom, and Gaming industries. Whether you're a startup aiming for scalability, an enterprise in digital transformation, or anything in between, we have the knowledge and experience to realize your vision.",
      s4: "Owision is dedicated to nurturing lasting partnerships. Beyond delivering software, we aim to be your strategic ally on the path to success. Explore the possibilities with Owision, where innovation meets excellence.",
      s5: "Owision is team of simple, honest and intelligent people who are passionate about identifying opportunities to make a good company great!. We value relationship with our customers, employees and suppliers as our measure of success.",
    },
    mission: {
      heading: "Our Mission",
      p: "At Owision, we are driven by a core mission - to empower businesses of all sizes with the technological tools they need to thrive in today's dynamic market. Our team of skilled and experienced developers, designers, and strategists collaborate seamlessly to create software solutions that not only meet but exceed the expectations of our clients.",
    },
  },
  contactUs: {
    heading: "Ready To",
    span1: "Connect, Collaborate, And Create Excellence?",
    span2: "We're Just A Click Away.!",
    contactDetails: {
      call: {
        heading: "Call Us",
        p: "Mon to Fri (10AM – 7PM)",
        button: "+46-84527113",
      },
      email: {
        heading: "Send an Email",
        p: "Get reply within 1 Business Day.",
        button: "info@owision.com",
      },
      meeting: {
        heading: "Book a Meeting",
        p: "30 Mins Online Call",
        button: "Book a meeting",
      },
    },
    form: {
      heading: "Just fill up these Details",
      subheading: "We will contact you within 24 hours.",
      name: { title: "Name", placeholder: "John Doe" },
      email: { title: "Email address", placeholder: "Your email address" },
      phone: {
        title: "Enter you phone number (Please enter Country Code as well)",
        placeholder: "Tel: +1 718 303 2844",
      },
      msg: { title: "Write Your message below", placeholder: "Your message" },
      button: "SEND",
    },
  },
  faqHeading: "Frequently Asked Questions",
  faq: [
    {
      question: "What services does your software development company offer?",
      answer:
        "We offer a comprehensive range of services, including custom software development, mobile app development, web development, and software consulting.",
    },
    {
      question: "How experienced is your development team?",
      answer:
        "Our development team consists of highly skilled and experienced professionals with expertise in diverse technologies and industries.",
    },

    {
      question: "Can you work with specific technologies or frameworks?",
      answer:
        "Yes, we are proficient in a wide array of technologies and frameworks. Our team is adaptable and can work with the technologies that best suit your project requirements.",
    },
    {
      question: "What industries do you specialize in?",
      answer:
        "We have experience working across various industries, including healthcare, finance, e-commerce, education, and more.",
    },
    {
      question: "How do you ensure the security of our project",
      answer:
        "Security is a top priority for us. We implement industry best practices, conduct regular security audits, and adhere to stringent data protection measures.",
    },

    {
      question:
        "What is the typical development process followed by your company?",
      answer:
        "Our development process involves detailed project analysis, design, development, testing, deployment, and ongoing support. We follow agile methodologies for flexibility and client involvement.",
    },
    {
      question:
        "Can you handle both small projects and large-scale enterprise solutions?",
      answer:
        "Absolutely. We cater to projects of all sizes, ensuring that our solutions align with the specific needs and goals of our clients.",
    },
    {
      question: "How do you handle project management and communication?",
      answer:
        "We employ robust project management tools and maintain transparent communication channels to keep clients informed about project progress.",
    },
    {
      question:
        "What is the average timeline for completing a software project?",
      answer:
        "Project timelines vary depending on the complexity and scope. We provide detailed project timelines during the initial consultation.",
    },
    {
      question:
        "How do you handle changes or updates during the development process?",
      answer:
        "We welcome feedback and changes throughout the development process. We follow an iterative approach, ensuring flexibility to accommodate client requirements.",
    },

    {
      question:
        "Do you offer ongoing maintenance and support after the project is completed?",
      answer:
        "Yes, we provide ongoing maintenance and support to ensure the smooth functioning of your software and promptly address any issues that may arise.",
    },
    {
      question:
        "Can you assist with integrating the new software with existing systems?",
      answer:
        "Absolutely. We have expertise in seamless integration to ensure that the new software complements and enhances your existing systems.",
    },
    {
      question: "How do you ensure the quality of the software?",
      answer:
        "Quality assurance is integral to our development process. We conduct thorough testing at each stage to identify and resolve any issues promptly.",
    },

    {
      question:
        "What happens if we encounter issues after the project is completed?",
      answer:
        "We offer post-launch support to address any issues that may arise. Our team is committed to ensuring the long-term success of your software.",
    },
    {
      question:
        "Can you provide references or case studies of your previous projects?",
      answer:
        "Certainly. We can provide references and case studies upon request to showcase our successful collaborations.",
    },
    {
      question: "How do you handle intellectual property and confidentiality?",
      answer:
        "We prioritize client confidentiality and adhere to strict intellectual property protection measures. We are open to signing non-disclosure agreements (NDAs) as needed.",
    },

    {
      question: "What is your pricing model?",
      answer:
        "Our pricing model is tailored to the specific requirements of each project. We provide transparent and detailed cost estimates during the initial consultation.",
    },
    {
      question: "How do you handle changes in project scope or requirements?",
      answer:
        "We understand that project scopes may evolve. We work closely with clients to accommodate changes and adjust timelines and costs accordingly.",
    },
    {
      question: "What is your approach to user experience (UX) design?",
      answer:
        "We prioritize user-centric design, ensuring that our solutions provide an intuitive and enjoyable experience for end-users.",
    },
    {
      question: "How can we get started with a project?",
      answer:
        "Simply reach out to us through our website or contact us directly. We'll schedule an initial consultation to discuss your project goals, requirements, and next steps.",
    },
  ],

  sap: {
    intro: {
      heading: "Introduction to SAP",
      desc: "SAP stands as a global leader in providing enterprise software solutions, designed to facilitate business operations and promote growth across various sectors. With its comprehensive suite of services, SAP caters to a wide array of business needs through its modules in Enterprise Resource Planning (ERP), Customer Relationship Management (CRM), Supply Chain Management (SCM), Human Resources (HR), and more. Whether you are a small business or a large enterprise, SAP delivers tailored solutions that help streamline your processes, enhance customer relations, and harness data-driven insights for strategic decision-making.",
    },
    module: {
      heading: "Key Modules of SAP",
      erp: {
        span: "SAP ERP :",
        li: "Integrates core business functions such as finance, sales, procurement, and manufacturing into a unified platform, enhancing real-time visibility and supporting effective decision-making.",
      },
      crm: {
        span: "SAP CRM",
        li: "Enhances customer engagement and sales by managing interactions across various channels, thereby improving customer satisfaction and business performance.",
      },
      scm: {
        span: "SAP SCM :",
        li: "Optimizes supply chain operations with tools for efficient demand and supply planning, inventory management, and logistics.",
      },
      hr: {
        span: "SAP HR :",
        li: "Manages human resources processes including payroll, talent management, and workforce planning to help attract, develop, and retain talen",
      },
      bi: {
        span: "SAP BI :",
        li: "Turns data into actionable insights through robust reporting, analytics, and visualization tools, aiding in comprehensive business analysis.",
      },
      more: {
        span: "And many more : ",
        li: "Including SAP HCM, SAP SRM, SAP EWM, SAP PLM, and SAP EHS, each addressing specific business needs and enhancing operational efficiency.",
      },
    },
    plan: {
      heading: "Our SAP Implementation Plan",
      desc: "We begin each SAP project with a thorough assessment of your business requirements, objectives, and current systems to tailor our approach. Our implementation process includes:",
      li1: "Detailed project planning with clear milestones and timelines.",
      li2: "Comprehensive team assembly involving consultants, developers, and stakeholders",
      li3: "Customization of SAP functionalities to meet unique business requirements.",
      li4: "Data migration from legacy systems to the SAP platform.",
      li5: "Rigorous testing to ensure functionality and performance.",
      li6: "User training and support to maximize system adoption and efficiency.",
      li7: "Phased rollouts to minimize operational disruption.",
    },
    implement: {
      heading: "Why Choose Us for Your SAP Implementation?",
      p: "Our deep expertise in SAP technologies and a proven track record of successful implementations make us the ideal partner for your SAP project. We prioritize client collaboration and are dedicated to transforming your business processes through innovative SAP solutions. By choosing us, you benefit from:",
      li1: "A seasoned team of experts committed to your project from start to finish.",
      li2: "Customized solutions designed to meet your unique business needs.",
      li3: "A focus on delivering tangible business outcomes and a high return on investment.",
      li4: "Continuous support and optimization of your SAP systems post-implementation.",
    },
    whySpa: {
      heading: "Why SAP?",
      desc: "SAP's unmatched combination of comprehensive capabilities and innovative solutions makes it the preferred choice for businesses aiming to future-proof their operations. With SAP's wide range of modules, businesses can streamline operations, foster digital transformation, and maintain a competitive edge in the dynamic market landscape.",
    },
    competitors: {
      heading: "SAP's Competitors",
      desc: "SAP competes with several other major players in the enterprise software market, including Oracle, Microsoft Dynamics 365, and Salesforce, each offering unique strengths and focuses. Choosing between SAP and its competitors will depend on specific business needs, industry requirements, and budget considerations.",
    },
    resolution: {
      heading: "Resolution",
      desc: "Leverage SAP's robust suite of enterprise software solutions to drive efficiency, growth, and success in your organization. With our expert guidance and comprehensive support, we'll help you navigate your SAP journey to achieve optimal results and a substantial return on investment. Embrace the power of SAP with us and transform your business into a more agile, insightful, and efficient enterprise.",
    },
  },
  privacy: {
    heading: "Privacy Policy",
    sub: "for Owision AB",
    p1: "This privacy policy concerns Owision AB and its website:",
    p2: "What personal data we collect and why we collect it",
    cookies: {
      heading: "Cookies",
      desc: "A “cookie” is a small text file that is stored on a computer for record-keeping purposes. We use cookies for analyzing trends, site administration, tracking user movement, and to gather demographic information from our base as a whole. Some cookies remain on your computer until you delete them. Other, like session ID cookies, expire when you close your browser. You may set your browser setting to attempt to reject cookies and you may still use the Service, however, certain features of the Service may not be available if your browser does not accept cookies. This site make use of Google Analytics which collects, stores and analyses web traffic data to help understanding how people are using the website.",
    },
    pixels: {
      heading: "Pixels",
      desc: "“Pixels” are tiny graphics with a unique identifier that are used to track the online movements of web users. Unlike cookies, which are stored on a computer’s hard drive, pixels are small graphics that are about the size of the period at the end of the sentence that are embedded invisibly on web pages or in HTML-based emails. Our third-party analytics providers may place pixels on the Site that track what other websites you visit (both before and after visiting the Site). Our third-party analytics providers use information obtained from pixels to help us improve our business and the Service. We do not control the use of pixels by third parties.",
    },
    thirdParty: {
      heading: "Third Party Analytics Providers",
      desc: "We use third parties to help us operate and improve the Service. In doing so, we may share with these third parties non-personally identifiable information about users’ use of the Service. These third party providers also use cookies. We use third party analytics providers, such as Google Analytics, to provide us with general demographic and interest-level information about our users and to help create a better user experience. We do not control information collected by third parties and are not responsible for their use of that information.",
    },
    embedded: {
      heading: "Embedded content from other websites",
      desc: "Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.",
    },
    analytics: {
      heading: "Analytics",
      desc: "This site makes use of Google Analytics which collects, stores and analyses web traffic data to help understanding how people are using the website. Google Analytics uses cookies and pixels in order to collect demographic and interest-level information and usage information from users that visit the Service, including but not limited to information about the pages where users enter and exit the Service and what pages users view on the Service, time spent, browser, operating system, and IP address. Cookies and pixels allow Google to recognize a user when a user visits the Service and when the user visits other websites. Google uses the information it collects from the Service and other websites to share with us and other website operators information about users including, but not limited to, age range, gender, geographic regions, general interests, and details about devices used to visit websites and purchase items. We take reasonable measures to prevent linking of information we receive from Google with any of your personally identifiable information. For more information regarding Google’s use of cookies and collection and use of information see the ",
      link: "Google Privacy Policy",
    },
    p3: "What rights you have over your data",
    p4: "If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.",
    p5: "Visitor data may be checked through an automated spam detection service and be sent to third-party products.",
  },
  cookies: {
    desc: "We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.",
    accept: "Accept all",
    reject: "Reject",
  },
  archer: {
    hero: {
      title: "Archer Solutions",
      subtitle: "True to the Success of Any Archer Solutions",
      cta: "Discover Our Solutions",
    },
    benefitsTitle: "Key Benefits",
    servicesTitle: "Our Services",
    services: [
      {
        id: 1,
        category: "IT Security & Risk Management",
        icon: <FaLock className="text-4xl text-[#313F78]" />,
        subServices: [
          "Communicating Risk across teams to the board",
          "Bring disparate teams together, across the firm",
          "Gain Visibility to stay top of new and changing environments",
        ],
      },
      {
        id: 2,
        category: "Business Resiliency",
        icon: <FaShieldAlt className="text-4xl text-[#313F78]" />,
        subServices: [
          "Ensure our BCP/ITDR plans work as needed, and avoid crisis scenarios",
          "Build resiliency into the business, especially into critical functions",
          "Coordinate better across multiple risk functions",
        ],
      },
      {
        id: 3,
        category: "Third Party Governance",
        icon: <FaHandshake className="text-4xl text-[#313F78]" />,
        subServices: [
          "Evaluate criticality of 3rd Party vendors",
          "Gain an overall picture of the risks they pose",
          "Are we on the right path to defend and leverage our ecosystem and ensure resiliency",
        ],
      },
      {
        id: 4,
        category: "Audit Management",
        icon: <FaClipboardCheck className="text-4xl text-[#313F78]" />,
        subServices: [
          "Enable audit team to easily align objectives with other stakeholders",
          "Assess audit entities, make audit plans for engagements, gain control & VISIBILITY of the entire audit lifecycle",
          "Better facilitate seamless engagements, documentation, enable quick reporting, consistently and quickly",
        ],
      },
      {
        id: 5,
        category: "Enterprise & Operational Risk Management",
        icon: <FaBuilding className="text-4xl text-[#313F78]" />,
        subServices: [
          "Ensure my organization is managing compliance risk consistently with other risks",
          "Demonstrate that my function is fulfilling its obligations and demonstrate compliance with all rules, regulations and standards",
          "Identify sources of privacy risk and show stakeholders we are managing it commensurate with the level of risk",
        ],
      },
    ],
    benefits: [
      {
        title: "Enhanced Trading Precision",
        description: "Optimal execution with minimal slippage",
        icon: <FaRocket className="text-4xl text-[#313F78]" />,
      },
      {
        title: "Market Performance",
        description: "Faster market access and execution",
        icon: <FaChartLine className="text-4xl text-[#313F78]" />,
      },
      {
        title: "Low Latency",
        description: "Ultra-fast processing capabilities",
        icon: <FaClock className="text-4xl text-[#313F78]" />,
      },
    ],
  },
  exchange: {
    hero: {
      title: "Exchange & Clearing Testing Services",
      subtitle: "Empowering Your Trading Success",
      cta: "Discover More",
    },
    serviceTitle: "Our Services",
    featureTitle: "Key Features",
    services: [
      {
        id: 1,
        title: "Exchange Testing",
        description: "Comprehensive testing solutions for exchange platforms",
        icon: <FaChartLine className="text-4xl text-[#313F78]" />,
        details:
          "Advanced testing methodologies ensuring robust exchange operations",
      },
      {
        id: 2,
        title: "Security Analysis",
        description: "In-depth security assessment services",
        icon: <FaShieldAlt className="text-4xl text-[#313F78]" />,
        details:
          "State-of-the-art security protocols and vulnerability testing",
      },
      {
        id: 3,
        title: "Performance Optimization",
        description: "Enhanced system performance solutions",
        icon: <FaRocket className="text-4xl text-[#313F78]" />,
        details: "Streamlined optimization techniques for maximum efficiency",
      },
    ],
    features: [
      {
        title: "Real-time Processing",
        description: "Lightning-fast transaction processing capabilities",
        icon: <FaUserClock className="text-4xl text-[#313F78]" />,
      },
      {
        title: "Scalable Architecture",
        description: "Highly scalable solutions for growing businesses",
        icon: <FaServer className="text-4xl text-[#313F78]" />,
      },
      {
        title: "Advanced Integration",
        description: "Seamless integration with existing systems",
        icon: <FaCog className="text-4xl text-[#313F78]" />,
      },
    ],
  },
};
