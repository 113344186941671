import React,{useEffect} from 'react'
import sapfront from '../Images/sapfront.png'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import sap1 from '../Images/sap1.png'
import sap2 from '../Images/sap2.png'
import sapbanner from '../Images/sapbanner.png'
import { useTranslation } from 'react-i18next'

const Sapcotent = () => {

    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])
      
    return (
        <>
            {/* <Navbar /> */}
            <div className="sap">
            <div className="sapBanner">
            <img src={sapfront} alt={sapfront} />
        </div>
        <div className="sapContent mainContainer">
            <div className="sapContnetLeft">
                        <h3>{t('sap.intro.heading') }</h3>
                        <p>{t('sap.intro.desc')}</p>
            </div>
            <div className="sapContentRight">
                <img src={sap1} alt="sap" />
            </div>
        </div>

        <div className="sapKeyContent mainContainer">
            <h3>{t('sap.module.heading')}</h3>
            <ol className="keyitem">
                <li><span>{t('sap.module.erp.span')}</span> {t('sap.module.erp.li')} </li>
                <li><span>{t('sap.module.crm.span')}</span> {t('sap.module.crm.li')} </li>
                <li><span>{t('sap.module.scm.span')}</span> {t('sap.module.scm.li')} </li>
                <li><span>{t('sap.module.hr.span')}</span>  {t('sap.module.hr.li')} </li>
                <li><span>{t('sap.module.bi.span')}</span>  {t('sap.module.bi.li')} </li>
                <li><span>{t('sap.module.more.span')}</span>{t('sap.module.more.li')}</li></ol>
        </div>
        <div className="implSap mainContainer">
        <div className="implsapLeft">
        <h3>{t('sap.plan.heading')}</h3>
        <p>{t('sap.plan.desc')}</p>
        <ul className="implItem">
        <li>{t('sap.plan.li1')}</li>
        <li>{t('sap.plan.li2')}</li>
        <li>{t('sap.plan.li3')}</li>
        <li>{t('sap.plan.li4')}</li>
        <li>{t('sap.plan.li5')}</li>
        <li>{t('sap.plan.li6')}</li>
        <li>{t('sap.plan.li7')}</li>
        </ul>
        </div>
        <div className="implRight">
        <img src={sap2} alt="sap2" />
        </div>
        </div>
        <div className="whysapContent mainContainer">
        <h1>{t('sap.implement.heading')}</h1>
        <img src={sapbanner} alt="sapbanner" />
        <p>{t('sap.implement.p')}</p>
        <ul className="sapItem">
        <li>{t('sap.implement.li1')}</li>
        <li>{t('sap.implement.li2')}</li>
        <li>{t('sap.implement.li3')}</li>
        <li>{t('sap.implement.li4')}</li>
        </ul>
        </div>
        <div className="whysapcontainer mainContainer">
        <div className="whysap">
        <h1>{t('sap.whySpa.heading') }</h1>
        <p>{t('sap.whySpa.desc')}</p>
        </div>
        <div className="sapComp">
        <h1>{t('sap.competitors.heading')}</h1>
        <p>{t('sap.competitors.desc')}</p>
        </div>
        <div className="conclusion">
        <h1>{t('sap.resolution.heading')}</h1>
        <p>{t('sap.resolution.desc')}</p>
        </div>
        </div>
        
            </div>
           
           
            <Footer />
        </>
    )


}


export default Sapcotent