"use client"

import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Logo from "../Images/owisionlogo.png";
import { Link, useLocation } from "react-router-dom"
import LanguageSelector from "./LanguageSelector"

const Navbar = () => {
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const [openMenu, setOpenMenu] = useState(false)
    const [activeLink, setActiveLink] = useState("/")
    const [showServiceMenu, setShowServiceMenu] = useState(false)

    useEffect(() => {
        setActiveLink(location.pathname)
    }, [location])

    const currentLanguage = i18n.language

    return (
        <div className="navbar_container">
            <div className="navbar_wrapper">
                <div className="navbar_logo">
                    <Link to={`/${currentLanguage}`}>
                        <img alt="Owision AB Logo" src={Logo} />
                    </Link>
                </div>
                <div className="menu_toggle">
                    <div className="menu_link">
                        <Link to={`/${currentLanguage}/`} className={activeLink === `/${currentLanguage}/` ? "active_blue" : ""}>
                            {t("navbar.home")}
                        </Link>
                        <div
                            className="relative"
                            onMouseEnter={() => setShowServiceMenu(true)}
                            onMouseLeave={() => setShowServiceMenu(false)}
                        >
                            <p
                                className={activeLink === `/${currentLanguage}/services` ? "active_blue" : ""}
                            >
                                {t("navbar.services")}
                            </p>
                            {showServiceMenu && (
                                <div className="absolute top-4 left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-999">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <Link
                                            to={`/${currentLanguage}/exchange`}
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            role="menuitem"
                                        >
                                            {t("navbar.exchange")}
                                        </Link>
                                        <Link
                                            to={`/${currentLanguage}/archer`}
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            role="menuitem"
                                        >
                                            {t("navbar.archer")}
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Link
                            to={`/${currentLanguage}/about-us`}
                            className={activeLink === `/${currentLanguage}/about-us` ? "active_blue" : ""}
                        >
                            {t("navbar.about")}
                        </Link>
                        <Link
                            to={`/${currentLanguage}/contact-us`}
                            className={activeLink === `/${currentLanguage}/contact-us` ? "active_blue" : ""}
                        >
                            {t("navbar.contact")}
                        </Link>
                        <Link
                            to={`/${currentLanguage}/faq`}
                            className={activeLink === `/${currentLanguage}/faq` ? "active_blue" : ""}
                        >
                            {t("navbar.faq")}
                        </Link>
                        <LanguageSelector />
                    </div>
                    <svg
                        onClick={() => setOpenMenu(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="#303F7A"
                        className="bi bi-list"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                        />
                    </svg>
                </div>

                {openMenu && (
                    <div className="full_screen_menu">
                        <div className="navbar_inner_wrapper" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <svg
                                onClick={() => setOpenMenu(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="black"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg>
                        </div>
                        <div className="menu_link_mobile">
                            <Link to={`/${currentLanguage}/`}>{t("navbar.home")}</Link>
                            <div className="relative">
                                <p>{t("navbar.services")}</p>
                                <div className="ml-4 mt-2">
                                    <Link to={`/${currentLanguage}/exchange`} className="block py-1">
                                        {t("navbar.exchange")}
                                    </Link>
                                    <Link to={`/${currentLanguage}/archer`} className="block py-1">
                                        {t("navbar.archer")}
                                    </Link>
                                </div>
                            </div>
                            <Link to={`/${currentLanguage}/about-us`}>{t("navbar.about")}</Link>
                            <Link to={`/${currentLanguage}/contact-us`}>{t("navbar.contact")}</Link>
                            <Link to={`/${currentLanguage}/faq`}>{t("navbar.faq")}</Link>
                            <LanguageSelector />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar

