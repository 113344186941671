import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'


function Hero() {
    const { t } = useTranslation();
    return (
        <section className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-[#1a237e] to-[#283593]">
            <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3')] bg-cover bg-center opacity-10" />
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="relative z-10 text-center px-4 max-w-4xl"
            >
                <h1 className="text-5xl md:text-7xl font-bold text-white mb-6">{t('archer.hero.title')}</h1>
                <p className="text-xl md:text-2xl text-gray-200 mb-8">{t('archer.hero.subtitle')}</p>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-white text-[#1a237e] px-8 py-4 rounded-lg font-bold text-lg shadow-lg hover:shadow-xl transition-all duration-300"
                >
                    {t('archer.hero.cta')}
                </motion.button>
            </motion.div>
        </section>
    )
}

export default Hero
