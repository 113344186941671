import {
  FaCode,
  FaBolt,
  FaNetworkWired,
  FaShieldAlt,
  FaCogs,
  FaBalanceScale,
  FaRocket,
  FaChartLine,
  FaClock,
  FaLock,
  FaHandshake,
  FaClipboardCheck,
  FaBuilding,
} from "react-icons/fa";

// export const services = [
//   {
//     id: 1,
//     title: "Algorithmic Trading Development",
//     description: "Design and deploy sophisticated trading algorithms",
//     icon: <FaCode className="text-4xl text-[#313F78]" />,
//     details: "Automated trading solutions with speed and accuracy",
//   },
//   {
//     id: 2,
//     title: "Low-Latency Solutions",
//     description: "Ultra-fast trade execution systems",
//     icon: <FaBolt className="text-4xl text-[#313F78]" />,
//     details: "Optimized infrastructure for competitive edge",
//   },
//   {
//     id: 3,
//     title: "Market Connectivity",
//     description: "Global exchange and liquidity provider access",
//     icon: <FaNetworkWired className="text-4xl text-[#313F78]" />,
//     details: "Secure and reliable trading gateways",
//   },
//   {
//     id: 4,
//     title: "Risk Management Tools",
//     description: "Real-time monitoring and alerts",
//     icon: <FaShieldAlt className="text-4xl text-[#313F78]" />,
//     details: "Comprehensive risk mitigation strategies",
//   },
//   {
//     id: 5,
//     title: "System Optimization",
//     description: "Performance tuning for high-volume trading",
//     icon: <FaCogs className="text-4xl text-[#313F78]" />,
//     details: "Peak performance under all market conditions",
//   },
//   {
//     id: 6,
//     title: "Compliance Solutions",
//     description: "Regulatory compliance frameworks",
//     icon: <FaBalanceScale className="text-4xl text-[#313F78]" />,
//     details: "MiFID II, Dodd-Frank, and EMIR compliance",
//   },
// ];

export const services = [
  {
    id: 1,
    category: "IT Security & Risk Management",
    icon: <FaLock className="text-4xl text-[#313F78]" />,
    subServices: [
      "Communicating Risk across teams to the board",
      "Bring disparate teams together, across the firm",
      "Gain Visibility to stay top of new and changing environments",
    ],
  },
  {
    id: 2,
    category: "Business Resiliency",
    icon: <FaShieldAlt className="text-4xl text-[#313F78]" />,
    subServices: [
      "Ensure our BCP/ITDR plans work as needed, and avoid crisis scenarios",
      "Build resiliency into the business, especially into critical functions",
      "Coordinate better across multiple risk functions",
    ],
  },
  {
    id: 3,
    category: "Third Party Governance",
    icon: <FaHandshake className="text-4xl text-[#313F78]" />,
    subServices: [
      "Evaluate criticality of 3rd Party vendors",
      "Gain an overall picture of the risks they pose",
      "Are we on the right path to defend and leverage our ecosystem and ensure resiliency",
    ],
  },
  {
    id: 4,
    category: "Audit Management",
    icon: <FaClipboardCheck className="text-4xl text-[#313F78]" />,
    subServices: [
      "Enable audit team to easily align objectives with other stakeholders",
      "Assess audit entities, make audit plans for engagements, gain control & VISIBILITY of the entire audit lifecycle",
      "Better facilitate seamless engagements, documentation, enable quick reporting, consistently and quickly",
    ],
  },
  {
    id: 5,
    category: "Enterprise & Operational Risk Management",
    icon: <FaBuilding className="text-4xl text-[#313F78]" />,
    subServices: [
      "Ensure my organization is managing compliance risk consistently with other risks",
      "Demonstrate that my function is fulfilling its obligations and demonstrate compliance with all rules, regulations and standards",
      "Identify sources of privacy risk and show stakeholders we are managing it commensurate with the level of risk",
    ],
  },
];
