import React from 'react'
import { benefits } from '../../utils/Archer/benefits'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'


function Benefits() {
    const { t } = useTranslation();
    return (
        <section className="py-20 px-4 md:px-8 bg-white">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center text-[#1a237e] mb-16">{t('archer.benefitsTitle')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                    {benefits.map((benefit, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.2 }}
                            className="p-8 bg-gray-50 rounded-xl border border-gray-100"
                        >
                            <div className="flex flex-col items-center text-center">
                                {benefit.icon}
                                <h3 className="text-xl font-bold text-[#1a237e] mt-4 mb-2">{t(`archer.benefits.${index}.title`)}</h3>
                                <p className="text-gray-600">{t(`archer.benefits.${index}.description`)}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Benefits
