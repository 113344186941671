import {
  FaChartLine,
  FaShieldAlt,
  FaCog,
  FaServer,
  FaRocket,
  FaUserClock,
} from "react-icons/fa";
export const features = [
  {
    title: "Real-time Processing",
    description: "Lightning-fast transaction processing capabilities",
    icon: <FaUserClock className="text-4xl text-[#313F78]" />,
  },
  {
    title: "Scalable Architecture",
    description: "Highly scalable solutions for growing businesses",
    icon: <FaServer className="text-4xl text-[#313F78]" />,
  },
  {
    title: "Advanced Integration",
    description: "Seamless integration with existing systems",
    icon: <FaCog className="text-4xl text-[#313F78]" />,
  },
];
