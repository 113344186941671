import React from 'react'
import { services } from '../../utils/Exchange/services'
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from "react-spring";
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation()
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const fadeIn = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0px)" : "translateY(50px)",
        config: { duration: 1000 }
    });
    return (
        <section ref={ref} className="py-20 px-4 md:px-8 bg-[#F5F5F5]">
            <animated.div style={fadeIn}>
                <h2 className="text-4xl font-bold text-center text-[#313F78] mb-16">{t('exchange.serviceTitle')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.03 }}
                            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
                        >
                            <div className="flex flex-col items-center text-center">
                                {service.icon}
                                <h3 className="text-2xl font-bold text-[#313F78] mt-4 mb-2">{t(`exchange.services.${index}.title`)}</h3>
                                <p className="text-gray-600 mb-4">{t(`exchange.services.${index}.description`)}</p>
                                <p className="text-sm text-[#5D7BAD]">{t(`exchange.services.${index}.details`)}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </animated.div>
        </section>
    )
}

export default Services
