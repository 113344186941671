import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

function Hero() {
    const { t } = useTranslation();
    return (
        <section className="relative h-screen flex items-center justify-center overflow-hidden bg-gradient-to-r from-[#313F78] to-[#5D7BAD]">
            <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1451187580459-43490279c0fa')] bg-cover bg-center opacity-20" />
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="relative z-10 text-center px-4"
            >
                <h1 className="text-5xl md:text-7xl font-bold text-white mb-10">{t('exchange.hero.title')}</h1>
                <p className="text-xl md:text-2xl text-white mb-8">{t('exchange.hero.subtitle')}</p>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-white text-[#313F78] px-8 py-4 rounded-full font-bold text-lg shadow-lg hover:shadow-xl transition-all duration-300"
                >
                    {t('exchange.hero.cta')}
                </motion.button>
            </motion.div>
        </section>
    )
}

export default Hero
