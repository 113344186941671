import React from "react";
import Hero from "../Components/Exchange/Hero";
import Services from "../Components/Exchange/Services";
import Feature from "../Components/Exchange/Feature";


const ExchangePage = () => {
    return (
        <div className="min-h-screen bg-white overflow-x-hidden scrollbar-thin scrollbar-thumb-[#313F78] scrollbar-track-gray-100">
            <Hero />
            <Services />
            <Feature />
        </div>
    );
};

export default ExchangePage;