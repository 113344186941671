import React from 'react'
import { services } from '../../utils/Archer/services'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation();
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const fadeIn = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0px)" : "translateY(50px)",
        config: { duration: 1000 }
    });
    return (
        // <section ref={ref} className="py-20 px-4 md:px-8">
        //     <animated.div style={fadeIn} className="max-w-7xl mx-auto">
        //         <h2 className="text-4xl font-bold text-center text-[#1a237e] mb-16">{t('archer.servicesTitle')}</h2>
        //         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        //             {services.map((service, index) => (
        //                 <motion.div
        //                     key={index}
        //                     whileHover={{ scale: 1.02 }}
        //                     className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100"
        //                 >
        //                     <div className="flex flex-col items-center text-center">
        //                         {service.icon}
        //                         <h3 className="text-2xl font-bold text-[#1a237e] mt-4 mb-2">{t(`archer.services.${index}.title`)}</h3>
        //                         <p className="text-gray-600 mb-4">{t(`archer.services.${index}.description`)}</p>
        //                         <p className="text-sm text-[#283593]">{t(`archer.services.${index}.details`)}</p>
        //                     </div>
        //                 </motion.div>
        //             ))}
        //         </div>
        //     </animated.div>
        // </section>
        <section ref={ref} className="py-20 px-4 md:px-8">
            <animated.div style={fadeIn} className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center text-[#1a237e] mb-16">{t('archer.servicesTitle')}</h2>
                <div className="space-y-12">
                    {services.map((service, serviceIndex) => (
                        <motion.div
                            key={service.id}
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100"
                        >
                            <div className="flex items-start space-x-6">
                                <div className="flex-shrink-0">
                                    {service.icon}
                                </div>
                                <div className="flex-grow">
                                    <h3 className="text-2xl font-bold text-[#1a237e] mb-4">{t(`archer.services.${serviceIndex}.category`)}</h3>
                                    <ul className="space-y-3 list-disc list-inside text-gray-600">
                                        {service.subServices.map((subService, index) => (
                                            <li key={index} className="text-lg">{t(`archer.services.${serviceIndex}.subServices.${index}`)}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </animated.div>
        </section>
    )
}

export default Services
