import { FaCode, FaBolt, FaNetworkWired, FaShieldAlt, FaCogs, FaBalanceScale, FaRocket, FaChartLine, FaClock } from "react-icons/fa";


export const benefits = [
  {
    title: "Enhanced Trading Precision",
    description: "Optimal execution with minimal slippage",
    icon: <FaRocket className="text-4xl text-[#313F78]" />,
  },
  {
    title: "Market Performance",
    description: "Faster market access and execution",
    icon: <FaChartLine className="text-4xl text-[#313F78]" />,
  },
  {
    title: "Low Latency",
    description: "Ultra-fast processing capabilities",
    icon: <FaClock className="text-4xl text-[#313F78]" />,
  },
];
