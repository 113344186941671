import React from 'react'
import { features } from '../../utils/Exchange/features'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

function Feature() {
    const { t } = useTranslation();
    return (
        <section className="py-20 px-4 md:px-8 bg-white">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center text-[#313F78] mb-16">{t('exchange.featureTitle')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.2 }}
                            className="p-6 bg-[#F5F5F5] rounded-xl"
                        >
                            <div className="flex flex-col items-center text-center">
                                {feature.icon}
                                <h3 className="text-xl font-bold text-[#313F78] mt-4 mb-6">{t(`exchange.features.${index}.title`)}</h3>
                                <p className="text-gray-600">{t(`exchange.features.${index}.description`)}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Feature
