import { useEffect } from "react";
import "../App.css";
import CookieConsent from "../Components/CookieConsent ";
import Footer from "../Components/Footer";
import HeroSection from "../Components/HeroSection";
import SecondSection from "../Components/Home/SecondSection";
import ThirdSection from "../Components/Home/ThirdSection";
import Navbar from "../Components/Navbar";
import ServiceOffered from "../Components/ServicesOffered";

const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
       <>
        <section>
            {/* <Navbar /> */}
            <HeroSection />
        </section>
        <section>
            <SecondSection />
        </section>
        <section>
            <ThirdSection />
        </section>
        <section>
            <ServiceOffered />
        </section>
          
        <section>
            <Footer />
        </section>
        <section>
            <CookieConsent />
        </section>
        
        
        </>

    )
}

export default HomePage