import React from "react";
import Hero from "../Components/Archer/Hero";
import Services from "../Components/Archer/Services";
import Benefits from "../Components/Archer/Benefits";


const ArcherPage = () => {

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 overflow-x-hidden">
            <Hero />
            <Services />
            <Benefits />
        </div>
    );
};

export default ArcherPage;